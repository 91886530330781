import React from "react";
import ArticlesSearch from "../components/ArticlesSearch";
import EventsSearch from "../components/EventsSearch";
import ChildGroupsFeed from "../components/ChildGroupsFeed";
import PeopleSearch from "../components/PeopleSearch";
import PublicationsSearch from "../components/PublicationsSearch";
import CoursesSearch from "../components/CourseSearch";
import VisitorSearch from "../components/VisitorSearch";

const ViewsEmbedParagraph: React.FC<JQI.ViewsEmbedParagraphProps> = ({
  view,
  hideSearch,
  groupId,
  label,
}) => {
  const args = view.arguments.split("/").filter(arg => !!arg);
  const searchAppProps = {
    args,
    groupId,
    hideSearch,
    label,
  } as JQI.ArticlesSearchProps | JQI.EventsSearchProps | JQI.PeopleSearchProps | JQI.PublicationsSearchProps | JQI.CoursesSearchProps;

  const d = new Date();
  const yesterday = d.setDate(d.getDate()-1);

  switch(view.id) {
    case "articles_search": {
      return <ArticlesSearch {...searchAppProps} />;
    }
    case "courses_search": {
      return <CoursesSearch {...searchAppProps} />;
    }
    case "past_courses_search": {
      return <CoursesSearch {...searchAppProps} queryArgs={[`semesterEpochDate <= ${yesterday}`]} indexName="courses_reverse_sort_index_" noResultsInfo="No upcoming courses found." />;
    }
    case "events_search": {
      return <EventsSearch {...searchAppProps} queryArgs={[`timestamp >= ${yesterday}`]} indexName="events_index_" noResultsInfo="No upcoming events found." />;
    }
    case "upcoming_events_search": {
      return <EventsSearch {...searchAppProps} queryArgs={[`timestamp >= ${yesterday}`]} indexName="events_index_" noResultsInfo="No upcoming events found." />;
    }
    case "past_events_search": {
      return <EventsSearch {...searchAppProps} queryArgs={[`timestamp < ${yesterday}`]} indexName="events_reverse_sort_index_" noResultsInfo="No past events found."  />;
    }
    case "people_search": {
      // Alumni and visitors should only appear on the explicit pages for those roles.
      if (!args.includes("20") && !args.includes("21")) {
        return <PeopleSearch {...searchAppProps} queryArgs={["NOT filters.roles:20", "NOT filters.roles:21"]} />;
      }
      return <PeopleSearch {...searchAppProps} />;
    }
    case "alumni_search": {
      return <PeopleSearch {...searchAppProps} queryArgs={["filters.roles:21"]} />;
    }
    case "visitors_search": {
      return <VisitorSearch {...searchAppProps} indexName="visitors_index_" queryArgs={[`timestamp >= ${yesterday}`]} noResultsInfo="No visitors found." />;
    }
    case "past_visitors_search": {
      return <VisitorSearch {...searchAppProps} indexName="visitors_reverse_sort_index_" queryArgs={[`timestamp < ${yesterday}`]} noResultsInfo="No past visitors found." />;
    }
    case "publications_search": {
      return <PublicationsSearch {...searchAppProps} />;
    }
    case "groups_feed": {
      return <ChildGroupsFeed args={args} />
    }
    default: {
      return null;
    }
  }
};

export default ViewsEmbedParagraph;
