const getExternalGroups = (
  groups: JQI.GroupFeedItemProps[],
  currentGroupId: number
) => {
  return groups.filter(
    (group) => group.drupal_internal__tid !== currentGroupId
  );
};

const getPublishedGroups = (groups: JQI.GroupFeedItemProps[]) => {
  return groups.filter(
    // "published" here means both that a group is published in Drupal and that Gatsby has created
    // a node for it (signified by the existence of the pagePath field added in onCreateNode)
    (group) => (group.status === true && !!group.fields?.pagePath)
  );
};

const filterUnrelatedChildren = (groups: JQI.GroupFeedItemProps[], currentGroupId: number) => {
  return groups.filter (group => {
    let keep = false;
    group.parentTerm.forEach(parent => {
      if (parent.drupal_internal__target_id === currentGroupId || parent.drupal_internal__target_id === null) {
        keep = true;
      }
    });
    return keep;
  });
};

const splitGroupsAndCenters = (groups: JQI.GroupFeedItemProps[]) => {
  return({
    centers: groups.filter((group) => group.parentTerm[0].drupal_internal__target_id === null),
    researchGroups: groups.filter((group) => group.parentTerm[0].drupal_internal__target_id !== null),
  });
};

const filterRelatedArticles = (
  articles: JQI.ArticleFeedItemProps[],
  currentGroupId: number
) => {
  return articles.filter((article) => {
    let groupMatch = false;
    article.field_sites.forEach((site) => {
      if (site.drupal_internal__target_id === currentGroupId) {
        groupMatch = true;
      }
    });
    return groupMatch;
  });
};

const filterRelatedPublications = (publications: JQI.Publication[], currentGroupId: number) => {
  return publications.filter((publication) => {
    let groupMatch = false;
    publication.field_publication_group.forEach((site) => {
      if (site.drupal_internal__target_id === currentGroupId) {
        groupMatch = true;
      }
    });
    return groupMatch;
  });
};

const filterPeople = (
  people: JQI.PersonFeedItemProps[],
  currentGroupId: number
) => {
  return people.filter((person) => {
    let groupMatch = false;
    person.field_sites?.forEach((site) => {
      if (site.drupal_internal__target_id === currentGroupId) {
        groupMatch = true;
      }
    });
    return groupMatch;
  });
};

export {
  getExternalGroups,
  getPublishedGroups,
  filterUnrelatedChildren,
  splitGroupsAndCenters,
  filterRelatedArticles,
  filterRelatedPublications,
  filterPeople,
};
