import React from "react";
import { useChildGroups } from "gatsby-jqi-graphql-helpers";
import GroupFeed from "../GroupFeed";
import GroupFeedItem from "../GroupFeedItem";
import "./ChildGroupsFeed.scss";

const ChildGroupsFeed: React.FC<JQI.ChildGroupsFeedProps> = ({
  args = [],
}) => {
  const groups = useChildGroups() as JQI.GroupFeedItemProps[];
  const filteredGroups = groups
    .filter((group) => {
      const parentTerms = group.parentTerm || [];
      const parentIds = parentTerms.map((term) => `${term.drupal_internal__target_id}`);
      for (let i = 0; i < parentIds.length; i++) {
        if (args.indexOf(parentIds[i]) !== -1 && !!group.fields?.pagePath) {
          return true;
        }
      }
      return false;
    });
  return (
    <div className="child-groups-feed">
      <GroupFeed>
        {
          filteredGroups.map((group, i) => (
            <GroupFeedItem key={`group-${i}`} {...group} />
          ))
        }
      </GroupFeed>
    </div>
  );
};

export default ChildGroupsFeed;