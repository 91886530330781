import React from "react";
import { GatsbyImage, GatsbyImageProps, getImage } from "gatsby-plugin-image";
import parse, { Element } from "html-react-parser";
import TextHTML from "../components/TextHTML";

const TextHTMLParagraph: React.FC<JQI.TextHTMLParagraphProps> = ({
  className,
  text,
}) => {
const parsedText = parse(text.processed, {
    replace(node) {
      const elementNode = node as Element;
      if (elementNode.type === "tag" && elementNode.name === "img") {
        const src = elementNode.attribs?.src;
        if (src) {
          elementNode.attribs.src = src.replace('/sites/default/files/', `${process.env.GATSBY_DRUPAL_URL}/sites/default/files/`);
        }
      }
      // For embedded remote video media, parse out the YouTube ID and replace the element with an iframe
      // TDOO: Handle Vimeo or other platforms if needed
      if (elementNode.type === "tag" && elementNode.name === "article" && elementNode.attribs['data-media-source'] === "remote_video") {
        const src = elementNode.attribs['data-media-source-value'];
        const videoId = src.split("=")[1];
        return <iframe width="560" height="315" src={`https://www.youtube.com/embed/${videoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      }
    },
  });
  return (
    <TextHTML className={className}>
      {parsedText}
    </TextHTML>
  );
};

export default TextHTMLParagraph;
