import React from "react";
import classNames from "classnames";
import algoliasearch, { SearchIndex } from "algoliasearch";
import SearchAppForm from "./SearchAppForm";
import SearchAppResults from "./SearchAppResults";
import "./SearchApp.scss";

export default class SearchApp extends React.Component<JQI.SearchAppProps, JQI.SearchAppState> {
  private algoliaIndex: SearchIndex;

  state: JQI.SearchAppState = {
    response: null,
    query: this.props.query,
    filters: !!this.props.filters ? [ ...this.props.filters ] : [],
    page: 0,
    fetching: true,
    errorFetching: false,
  };

  constructor(props: JQI.SearchAppProps) {
    super(props);
    const algoliaAppId = process.env.GATSBY_ALGOLIA_APP_ID || "";
    const algoliaAdminKey = process.env.GATSBY_ALGOLIA_ADMIN_KEY || "";
    const algoliaClient = algoliasearch(algoliaAppId, algoliaAdminKey);
    this.algoliaIndex = algoliaClient.initIndex(this.props.indexName);
  }

  private fetchResults = () => {
    let filterString = this.state.filters
      .filter((filter) => !!filter.value)
      .map((filter) => {
        if (filter.property === "timestamp") {
          return `${filter.property}:${(new Date(filter.value)).getTime()}`;
        }
        return `${filter.property}:${filter.value}`;
      })
      .join(" AND ");

    // Merge queryArgs into filterString
    if (!!this.props.queryArgs?.length) {
      const queryArgsString = this.props.queryArgs.join(" AND ");
      if (!!filterString) {
        filterString += ` AND ${queryArgsString}`;
      } else {
        filterString = queryArgsString;
      }
    }

    this.state.fetching = true;
    this.state.errorFetching = false;
    this.algoliaIndex
      .search(this.state.query || "", {
        filters: filterString,
        page: this.state.page,
      })
      .then((response) => {
        this.setState({
          fetching: false,
          response,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          fetching: false,
          errorFetching: true,
        });
      });
  }

  private handleSubmit = () => {
    this.fetchResults();
  }

  private handleReset = () => {
    this.setState({
      query: this.props.query,
      filters: !!this.props.filters ? this.props.filters.map((filter) => ({
        ...filter,
        value: "",
      })) : [],
      page: 0,
    }, () => {
      if (!this.props.preventInitialLoad) {
        this.fetchResults();
      } else {
        this.setState({
          response: null,
        });
      }
    });
  }

  private handleQueryChange = (query: string) => {
    this.setState({
      query,
    });
  }

  private handleFilterChange = (property: string, value: string) => {
    this.setState({
      page: 0,
      filters: this.state.filters.map((filter) => {
        if (property === filter.property) {
          return {
            ...filter,
            value,
          }
        } else {
          return filter;
        }
      }),
    })
  }

  private handlePageChange = (index: number) => {
    this.setState({
      page: index,
    }, () => {
      this.fetchResults();
    });
  }

  componentDidMount() {
    if (!this.props.preventInitialLoad) {
      this.fetchResults();
    }
  }

  render() {
    const { className, feedType, hideSearch } = this.props;
    const { page, response, fetching, errorFetching } = this.state;
    const classList = classNames({
      "search-app": true,
      [`search-app--${feedType}`]: !!feedType,
      [`${className}`]: !!className,
    });
    return (
      <div className={classList}>
        {
          !hideSearch &&
          <SearchAppForm
            query={this.state.query}
            filters={this.state.filters}
            onSubmit={this.handleSubmit}
            onReset={this.handleReset}
            onQueryChange={this.handleQueryChange}
            onFilterChange={this.handleFilterChange}
            enableTextSearch={this.props.enableTextSearch}
          />
        }
        <SearchAppResults
          response={response}
          feedType={feedType}
          fetching={fetching}
          currentPage={page}
          errorFetching={errorFetching}
          onPageChange={this.handlePageChange}
          noResultsText={this.props.noResultsText}
        />
      </div>
    );
  }
};
